import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "./reusables"
import useInterval from "../custom-hooks/useInterval"
import useLocalStorage from "../custom-hooks/useLocalStorage"
import { TiLocationArrow } from "react-icons/ti"
import { motion } from "framer-motion"

const Background = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: contain;
    padding: 10px;

    max-width: 600px;
    margin: 0 auto;
`
const ButtonContainer = styled(motion.button)`
    background: none;
    border: none;
    outline: none;
    /* padding: 30px; */
    position: absolute;
    bottom: 2.5vh;
    right: 3vh;
    z-index: 2;
    cursor: pointer;
    color: white;
`
const CTA = styled.h2`
    font-size: 50px;
    line-height: 0.9;
    text-align: left;
    font-family: "Montserrat";
    font-weight: 700;
    width: 200px;
    color: #373737;
    transition: 0.2s ease-out;
    text-transform: uppercase;

    :hover {
        color: white;
    }
`
const ArrowCont = styled(motion.span)`
    position: absolute;
    top: -63px;
    right: 30px;
`
const BlackoutHome = ({ setStep }) => {
    const [entered, setEntered] = useLocalStorage("entered", "")

    //   useEffect(() => {
    //     alert("Home")
    // }, [])

    //TURN ON FOR LIVE
    const [activeStep, setActiveStep] = useState(entered ? "intro" : "contact")

    const [index, setIndex] = useState(0)
    useInterval(() => {
        setIndex(index + 1)
    }, 1000)

    const _renderColor = () => {
        switch (index) {
            case 0:
                return "#fff3d7"
            case 1:
                return "#ffd7d7"
            case 2:
                return "#d7fffd"
            case 3:
                return "#f1ffd7"
            case 4:
                return "#d7ffda"

            default:
                setIndex(0)
                break
        }
    }

    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            <Background src={require("../../images/blackout-cover.png")} />
            <ButtonContainer onClick={() => setStep(activeStep)}>
                <CTA>Ya Pheal Me</CTA>
                <ArrowCont
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, flip: Infinity }}
                >
                    <TiLocationArrow color={_renderColor()} size={120} />
                </ArrowCont>
            </ButtonContainer>
        </Container>
    )
}

export default BlackoutHome
