import styled from "styled-components"
import { motion } from "framer-motion"
import { fadeDuration } from "../../../project-constants"

export const Container = styled(motion.div)`
    /* font-family: "Montserrat"; */
    text-align: center;
    padding: 20px 40px;
    /* width: 100%; */
    margin: 0 auto;
    max-width: 600px;
`
export const Button = styled.button`
    border: ${props =>
        props.color === "dark" ? "3px solid white" : "3px solid black"};
    background: none;
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;
    font-family: "Haas";
    min-width: ${props => (props.small ? "120px" : "200px")};
    min-height: ${props => (props.small ? "50px" : "70px")};
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
    margin: 0 15px;
    margin-bottom: 20px;

    :hover {
        background: ${props => (props.fillColor ? props.fillColor : "none")};
        /* border: 1px solid white; */
    }

    :focus {
        outline: none;
    }

    @media (max-width: 550px) {
        min-width: 150;
        min-height: 60px;
        min-height: ${props => (props.small ? "50px" : "60px")};
        font-size: 18px;
    }
`
