import React, { useState } from "react"
import styled from "styled-components"
import { Container, Button } from "./reusables"
import Loader from "react-loader-spinner"
import { useEffect } from "react"
import { UploadEmail } from "../../services/parse-bridge"
import useLocalStorage from "../custom-hooks/useLocalStorage"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { nameField, emailField, colorScheme } from "../../state/atoms"

const Heading = styled.p`
    margin-bottom: 20px;
`

const Start = ({ setStep }) => {
    const enteredName = useRecoilValue(nameField)
    const enteredEmail = useRecoilValue(emailField)
    const setColor = useSetRecoilState(colorScheme)

    const [entered, setEmailEntered] = useLocalStorage("entered", "")

    const [doneUploading, setDoneUploading] = useState(false)
    const [doneFakeLoading, setDoneFakeLoading] = useState(false)

    useEffect(() => {
        // alert("Start")
        setColor("dark")
    }, [])

    useEffect(() => {
        if (!entered) {
            uploadEmail()
        } else {
            setDoneUploading(true)
        }
    }, [enteredEmail])

    useEffect(() => {
        setTimeout(() => {
            setStep("result")
        }, 2500)
    }, [doneUploading])

    const uploadEmail = () => {
        UploadEmail(enteredName, enteredEmail).then(res => {
            if (!res.success) {
                //Shouldn't really hamper the user experience at all, just let
                //em continue
                console.log(res.error)
            } else {
                setEmailEntered("true")
                setDoneUploading(true)
            }
        })
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (doneUploading) {
    //             setStep('result')
    //             setDoneFakeLoading(true)
    //         }
    //     }, 2500)
    // }, [])

    return (
        <Container
            style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Heading>Getting your educational resource</Heading>
            <Loader type="Circles" width={50} height={50} color="white" />
        </Container>
    )
}

export default Start
