import React from "react"
import styled from "styled-components"
import { BodyText, DigestiveText } from "../../styles/typography"

const Container = styled.div``
const Title = styled(DigestiveText)`
    font-size: clamp(120px, 10vw, 280px);
`
const Subtitle = styled(BodyText)`
    font-size: clamp(18px, 5vw, 45px);
`

const TitleBlock = ({ title, subtitle }) => {
    return (
        <Container>
            <Title>{title}</Title>
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : []}
        </Container>
    )
}

export default TitleBlock
