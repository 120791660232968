import Parse from 'parse'

//Initialize Parse
Parse.initialize(process.env.GATSBY_PARSE_APP_ID, process.env.GATSBY_PARSE_JS_KEY)
Parse.serverURL = "https://parseapi.back4app.com"

export async function UploadEmail(name, emailAddress) {
    const Email = Parse.Object.extend("Email")
    const email = new Email()
  
    email.set("name", name)
    email.set("email", emailAddress)
  
    try {
      const createdEmail = await email.save()
  
      return {
        success: true,
        id: createdEmail.id,
      }
    } catch (error) {
      return {
        success: false,
        error: error.message,
      }
    }
  }