import React, { useEffect } from "react"
import { useRecoilState } from "recoil"
import styled from "styled-components"
import { TextWrapper } from "../../layout/reusables"
import { contentType } from "../../state/atoms"
import { colors } from "../../styles/colors"
import { BodyText } from "../../styles/typography"
import TitleBlock from "../shared/title-block"
import { Button } from "./reusables"

const WalkthroughIntro = ({ setActiveStep }) => {
    const [type, setType] = useRecoilState(contentType)

    useEffect(() => {
        if (type) {
            setActiveStep("start")
        }
    }, [type])

    return (
        <Container>
            <TitleBlock title="PHEAL" subtitle="Channel" />
            <TextWrapper style={{ marginTop: 50 }}>
                <BodyText
                    style={{
                        fontSize: 18,
                        opacity: 0.85,
                        fontWeight: 400,
                        marginBottom: 50,
                    }}
                >
                    Here’s an example of a channel whose aim is to buiild
                    awareness on racial issues.
                </BodyText>

                <CTA>
                    <BodyText style={{ marginBottom: 30 }}>
                        How are you phealing to learn?
                    </BodyText>
                    <ButtonGroup>
                        <Button
                            style={{
                                borderColor: colors.blue,
                            }}
                            color={"dark"}
                            fillColor={colors.blue}
                            onClick={() => setType("Video")}
                        >
                            watch
                        </Button>
                        <Button
                            style={{ borderColor: colors.orange }}
                            color={"dark"}
                            fillColor={colors.orange}
                            onClick={() => setType("Read")}
                        >
                            read
                        </Button>
                    </ButtonGroup>
                </CTA>
            </TextWrapper>
        </Container>
    )
}

export default WalkthroughIntro

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const ButtonGroup = styled.div`
    margin-bottom: 30px;
`

const CTA = styled.div``
