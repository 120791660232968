import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container, Button } from "./reusables"
import { useRecoilValue } from "recoil"
import { contentType, colorScheme } from "../../state/atoms"
import { fadeDuration } from "../../../project-constants"
import FullList from "./full-list"

const Heading = styled.p`
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;
    margin-bottom: 20px;
`
const ResultContainer = styled.div`
    font-family: "Montserrat";
    margin-bottom: 10px;
`
const ResultTitle = styled.h3`
    font-weight: 600;
    font-size: 42px;
    font-family: "Haas";
    max-width: 650px;
    margin-bottom: 9px;
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;

    @media (max-width: 550px) {
        font-size: 36px;
    }
`
const ResultMeta = styled.p`
    opacity: 0.5;
    font-family: "Montserrat";
    color: ${props => (props.color === "dark" ? "white" : "black")};
    margin-bottom: 20px;
    transition: ${fadeDuration}s ease;
`
const TryAgain = styled.button`
    background: none;
    border: none;
    outline: none;
    opacity: 0.5;
    font-family: "Montserrat";
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;
    cursor: pointer;
    margin-bottom: 30px;
`

const Result = ({ resources, setColor, setBgColor, setStep }) => {
    const type = useRecoilValue(contentType)

    const [resource, setResource] = useState(null)

    const [color] = useRecoilValue(colorScheme)

    useEffect(() => {
        getRandomResource(type)
    }, [type])

    useEffect(() => {
        setTimeout(() => {
            setColor("light")
        }, 200)
    }, [])

    const handleTryAgain = () => {
        setColor("dark")
        setBgColor("black")
        setStep("start")
    }

    const getRandomResource = type => {
        const filteredResources = resources.filter(el => el.node.type === type)
        const randomResource =
            filteredResources[
                Math.floor(Math.random() * filteredResources.length)
            ]
        setResource(randomResource)
    }

    if (!resource) {
        return null
    }

    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Heading color={color}>Your Educational Resource</Heading>
            <ResultContainer>
                <ResultTitle color={color}>{resource.node.name}</ResultTitle>
                <ResultMeta color={color}>{resource.node.note}</ResultMeta>
                <a target="_blank" href={resource.node.link}>
                    <Button color={color}>
                        {type === "Video" ? "watch" : "read"} now
                    </Button>
                </a>
            </ResultContainer>
            <TryAgain onClick={() => handleTryAgain()}>
                Don't pheal it? Draw again
            </TryAgain>
            <hr style={{ opacity: 0.3 }} />
            <FullList resources={resources} />
        </Container>
    )
}
export default Result
